import { useState } from "react";
import style from "./navBarLeft.module.css";
import logoSipremo from "../../images/SipremoSemFundopng.png";

import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';
import { useNavigate } from "react-router-dom";
import IosShareIcon from '@mui/icons-material/IosShare';
import AddchartIcon from '@mui/icons-material/Addchart';

function NavBarLeft({ page }) {
  const [typePage, setTypePage] = useState("people");
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(true); // Estado para o menu colapsado

  const use = localStorage.getItem("user");
  const userData = JSON.parse(use);  // Atribuir o JSON parse a uma constante para reutilização

  const navigation = useNavigate();

  const handleChangePageToLogin = () => {
    navigation("/");
  };

  const handleChangePage = (newPage, newTypePage) => {
    page(newPage);
    setTypePage(newTypePage);
  };

  const toggleMenu = () => {
    setIsMenuCollapsed(!isMenuCollapsed); 
  };

  return (
    <div className={`${style["App"]} ${isMenuCollapsed ? style["collapsed"] : ""}`}>
      <div className={style["menuIcon"]} onClick={toggleMenu}>
        <MenuIcon /> 
        <div className={style["menuText"]}>{isMenuCollapsed ? "S" : "SIPREMO"}</div>
      </div>
      <div className={style["menuIconPersonContent"]}>
        <div
          className={typePage === "people" ? style["menuIconSelected"] : style["menuIconSelect"]}
          onClick={() => handleChangePage("people", "people")}
        >
          <PeopleOutlineIcon />
          {!isMenuCollapsed && <div>Usuários</div>}
        </div>
        <div
          className={typePage === "report" ? style["menuIconSelected"] : style["menuIconSelect"]}
          onClick={() => handleChangePage("report", "report")}
        >
          <ContentPasteSearchIcon />
          {!isMenuCollapsed && <div>Relatório</div>}
        </div>
        <div
          className={typePage === "data" ? style["menuIconSelected"] : style["menuIconSelect"]}
          onClick={() => handleChangePage("data", "data")}
        >
          <DataSaverOnIcon />
          {!isMenuCollapsed && <div>Painel</div>}
        </div>
        {userData.username === "l0.h0" && (
          <>
            <div
              className={
                typePage === "apresentacao"
                  ? style["menuIconSelectedApresentacao"]
                  : style["menuIconSelectApresentacao"]
              }
              onClick={() => handleChangePage("apresentacao", "apresentacao")}
            >
              <IosShareIcon />
              {!isMenuCollapsed && <div>Testes</div>}
            </div>
            <div
              className={
                typePage === "relatorioApresentacao"
                  ? style["menuIconSelectedApresentacao"]
                  : style["menuIconSelectApresentacao"]
              }
              onClick={() => handleChangePage("relatorioApresentacao", "relatorioApresentacao")}
            >
              <AddchartIcon />
              {!isMenuCollapsed && <div>Dados</div>}
            </div>
          </>
        )}
      </div>
      <div className={style["menuIconExit"]} onClick={handleChangePageToLogin}>
        <LogoutIcon />
        {!isMenuCollapsed && <div>Sair</div>}
      </div>
    </div>
  );
}

export default NavBarLeft;