// Importação do React
import { useState } from "react";

// importação de terceiros
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

// Importação de Style
import style from "./signin.module.css";
import "react-toastify/dist/ReactToastify.css";

// Importação de Imagens
import mina from "../../images/mina.jpeg";
import logo from "../../images/logoLogin.jpg";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";

// Importação de navegação
import { useNavigate } from "react-router-dom";

// Importação de rotas
import API_ROUTES from "../../config/endpoints";

// Importação de Icones
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function Signin() {
  // State de manipulação de elemento
  const [showPassword, setShowPassword] = useState(false);

  // State de dados
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");

  // constante de navegação
  const navigation = useNavigate();

  // notificação de erro
  const notifyError = (text) => toast.error(text);

  // Alteração do valor user
  const handleChangeUser = (value) => {
    let variable = value.target.value;
    setUser(variable);
  };

  const handleLogin = async () => {
    await axios
      .get(API_ROUTES.USER_BY_USERNAME(user))
      .then((res) => {
        if (res.status === 200) {
          let data = res.data;
          if (data.password === password && data.sector.hierarchy == "0") {
            localStorage.setItem("user", JSON.stringify(data));
            navigation("/Home");
          } else {
            notifyError("Usuário não encontrado!");
          }
        }
      })
      .catch((err) => {
        notifyError("Usuário não encontrado!");
      });
  };

  // Alteração do valor password
  const handleChangePassword = (value) => {
    let variable = value.target.value;
    setPassword(variable);
  };

  // Modifica a visualização da senha
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangePageToRecoverPass = () => {
    navigation("/recoverPass");
  };

  return (
    <div className={style["App"]}>
      {/* Formulario de acesso */}
      <div className={style["Form"]}>
        <div className={style["Data-Login"]}>
          <div className={style["logo-div"]}>
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img src={logo} className={style["logo"]} />
          </div>
          <div>
            <p className={style["Title"]}>Login</p>
          </div>

          {/* Input de usuario */}
          <Box
            component="form"
            sx={{
              "& > :not(style)": { width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="outlined-basic"
              label="Usuário"
              variant="outlined"
              onChange={(value) => {
                handleChangeUser(value);
              }}
            />
          </Box>

          {/* Input de Senha */}
          <Box
            component="form"
            sx={{
              "& > :not(style)": { width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
            <FormControl sx={{ width: "25ch" }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                onChange={(value) => {
                  handleChangePassword(value);
                }}
                label="Password"
              />
            </FormControl>
          </Box>

          {/* Encaminhamento de esquecimento de senha */}
          <div>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <p
              className={style["recoverPass"]}
              onClick={handleChangePageToRecoverPass}
            >
              Esqueci Minha Senha
            </p>
          </div>

          {/* Botão de entrar */}
          <button className={style["Button"]} onClick={handleLogin}>
            Entrar
          </button>
        </div>
        <ToastContainer />
      </div>
      {/* Div Image */}
      <div className={style["Image"]}>
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img src={mina} className={style["Image-Render"]} />
      </div>
    </div>
  );
}

export default Signin;
