import React, { useState, useCallback, useEffect } from "react";
import {
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  ReferenceArea,
  BarChart,
  Bar,
  Legend,
} from "recharts";
import style from "./BodyDataBody.module.css";
import InformationDiv from "./informations/informations";
import PersonIcon from "@mui/icons-material/Person";
import axios from "axios";
import API_ROUTES from "../../config/endpoints";
import { toast, ToastContainer } from "react-toastify";
import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

function BodyDataBody({ usuario, bodyRequest }) {
  const [refAreaLeft, setRefAreaLeft] = useState(null); // Inicialize como null
  const [refAreaRight, setRefAreaRight] = useState(null); // Inicialize como null
  const [zoomState, setZoomState] = useState({
    left: "dataMin",
    right: "dataMax",
    top: "dataMax+1",
    bottom: "dataMin",
    top2: "dataMax+2",
    bottom2: "dataMin+5",
  });
  const [qtdePeople, setQtdePeople] = useState(0);
  const [qtdeIntern, setQtdeIntern] = useState(0);
  const [qtdeExtern, setQtdeExtern] = useState(0);
  const [qtdeConfirmated, setQtdeConfirmated] = useState(0);
  const [maiorRegistry, setMaiorRegistry] = useState(0);

  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);

  const notifyError = (text) => toast.error(text);

  const getInformations = async () => {
    await axios
      .get(API_ROUTES.ALL_COMUNICATION)
      .then(({ data }) => {
        let intern = 0;
        let extern = 0;
        let confirmation = 0;

        setQtdePeople(data.length);
        data.map((dt) => {
          if (dt.recipient.sector.hierarchy == 2) {
            extern += 1;
          } else {
            intern += 1;
          }

          if (dt.read) {
            confirmation += 1;
          }
        });

        setQtdeExtern(extern);
        setQtdeIntern(intern);
        setQtdeConfirmated(confirmation);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getResponseForGroup = async () => {
    await axios
      .get(API_ROUTES.GET_USERS_GROUP_BY_GROUP)
      .then(({ data }) => {
        setData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getResponseForConfirmations = async () => {
    await axios
      .get(API_ROUTES.GET_REGISTRY_CONFIRMATION_PER_MINUTE)
      .then(({ data }) => {
        setInitialData(data);

        data.map((dt) => {
          if (maiorRegistry < dt.confirmacao) {
            setMaiorRegistry(dt.confirmacao + 2);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { datahora, confirmacao, pessoas } = payload[0].payload;
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            padding: "10px",
            border: "1px solid #ccc",
          }}
        >
          <p>{`Período: ${datahora}`}</p>
          <p>{`Total de Confirmações: ${confirmacao}`}</p>
          {pessoas && pessoas.length > 0 ? (
            <TableContainer component={Paper} sx={{ marginTop: "25px" }}>
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell align="right">Horário de Confirmação</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pessoas.map((p, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {p.nome}
                    </TableCell>
                    <TableCell align="right">{p.confirmationHour}</TableCell>
                  </TableRow>

                  // <p className="envios">{`${index + 1}° - ${p}`}</p>
                ))}
              </TableBody>
            </TableContainer>
          ) : (
            <p>Ninguém Confirmou no Período Indicado</p>
          )}
        </div>
      );
    }
    return null;
  };

  const getAxisYDomain = useCallback(
    (from, to, ref, offset) => {
      const refData = initialData.slice(from - 1, to);
      let [bottom, top] = [refData[0][ref], refData[0][ref]];
      refData.forEach((d) => {
        if (d[ref] > top) top = d[ref];
        if (d[ref] < bottom) bottom = d[ref];
      });

      return [0, (top | 0) + offset];
    },
    [initialData]
  );

  const zoom = useCallback(() => {
    try {
      if (refAreaLeft === null || refAreaRight === null) return;

      if (refAreaLeft > refAreaRight) {
        [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];
      }

      const [bottom, top] = getAxisYDomain(
        refAreaLeft,
        refAreaRight,
        "envios",
        0
      );
      const [bottom2, top2] = getAxisYDomain(
        refAreaLeft,
        refAreaRight,
        "envios",
        1
      );

      setZoomState({
        left: refAreaLeft,
        right: refAreaRight,
        bottom,
        top,
        bottom2,
        top2, // Aqui o topo terá o valor máximo + 1
      });
      setRefAreaLeft(null);
      setRefAreaRight(null);
    } catch {
      notifyError("Erro ao realizar o zoom");
    }
  }, [refAreaLeft, refAreaRight, getAxisYDomain]);

  const zoomOut = useCallback(() => {
    setZoomState({
      left: "dataMin",
      right: "dataMax",
      top: "dataMax",
      bottom: "dataMin",
      top2: "dataMax+2",
      bottom2: "dataMin+5",
    });
    setRefAreaLeft(null);
    setRefAreaRight(null);
  }, []);

  useEffect(() => {
    getResponseForGroup();
    getInformations();
    getResponseForConfirmations();
  }, []);
  useEffect(() => {
    setInterval(() => {
      getResponseForGroup();
      getInformations();
      getResponseForConfirmations();
    }, 60000);
  }, []);

  const CustomTooltipBar = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const people = payload.find((p) => p.dataKey === "people");
      const grp = people.payload.name;
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            padding: "10px",
            border: "1px solid #ccc",
          }}
        >
          <p>{`${grp === "2" ? "Externos" : "Internos"}`}</p>
          <p>{`${people.value}% respondeu`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className={style["App"]}>
      <div className={style["title-module"]}>
        Painel de Inteligência Notificações PAEBM
      </div>
      <div className={style["grid-info"]}>
        <div className={style["number-users"]}>
          <text className={style["title"]}>Qtde. de Enviados</text>
          <text className={style["number"]}>{qtdePeople}</text>
          <div className={style["container-icon"]}>
            <PersonIcon className={style["icons"]} />
          </div>
        </div>

        <InformationDiv number={qtdeIntern} titleNumber={"Qtde. Internos"} />
        <InformationDiv number={qtdeExtern} titleNumber={"Qtde. Externos"} />
        <InformationDiv
          number={qtdeConfirmated}
          titleNumber={"Qtde. de Confirmações"}
        />
      </div>
      <div className={style["container-grafics"]}>
        <div className={style["grafic-line"]}>
          <text className={style["title-white"]}>Confirmações Por Agentes</text>
          <div
            className="highlight-bar-charts"
            style={{ userSelect: "none", width: "100%" }}
          >
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <XAxis
                  dataKey="name"
                  tickFormatter={(value) => {
                    if (value === "1") return "Interno";
                    if (value === "2") return "Externo";
                    return value;
                  }}
                />
                <YAxis />
                <Tooltip content={<CustomTooltipBar />} />
                <Bar dataKey="people" stackId="a" fill="#8947F4" />
                <Bar dataKey="porcent" stackId="a" fill="#fff" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className={style["grafic-line"]}>
          <text className={style["title-white"]}>
            Frequência de Confirmação
          </text>
          <div
            className="highlight-bar-charts"
            style={{ userSelect: "none", width: "100%" }}
          >
            <button type="button" className={style["btn"]} onClick={zoomOut}>
              Remover Zoom
            </button>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart
                width={1500}
                height={400}
                data={initialData}
                onMouseDown={(e) => {
                  if (e) setRefAreaLeft(e.activeLabel);
                }}
                onMouseMove={(e) => {
                  if (refAreaLeft) setRefAreaRight(e.activeLabel);
                }}
                onMouseUp={zoom}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  allowDataOverflow
                  dataKey="id"
                  domain={[zoomState.left, zoomState.right]}
                  type="number"
                  tickFormatter={(id) => {
                    const item = initialData.find((d) => d.id === id);
                    return item ? item.legend : id;
                  }}
                />
                <YAxis
                  allowDataOverflow
                  domain={[zoomState.bottom, zoomState.top]}
                  type="number"
                  yAxisId="1"
                />
                <YAxis
                  orientation="right"
                  allowDataOverflow
                  domain={[zoomState.bottom2, zoomState.top2]}
                  type="number"
                  yAxisId="2"
                />
                <Tooltip content={<CustomTooltip />} />
                <Line
                  yAxisId="2"
                  type="natural"
                  dataKey="confirmacao"
                  stroke="#8947F4"
                  animationDuration={300}
                />
                {refAreaLeft && refAreaRight ? (
                  <ReferenceArea
                    yAxisId="1"
                    x1={refAreaLeft}
                    x2={refAreaRight}
                    strokeOpacity={0.3}
                  />
                ) : null}
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default BodyDataBody;
