// Importação de componentes e React
import React, { useEffect, useState } from "react";

// importação de estilização
import style from "./bodyPeopleBodyEFNI.module.css";

// Importação de configuração manual
import localeText from "../../config/localeText";
import API_ROUTES from "../../config/endpoints";

// Importação de biblioteca de terceiros
import axios from "axios";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import * as locales from "@mui/material/locale";
import Typography from "@mui/material/Typography";
import { IconButton, Input } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import BodyModal from "../bodyModalAddOrEditUser/bodyModal";
import CircularProgress from "@mui/material/CircularProgress";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

function BodyPeopleBodyEFNI() {
  // constante de input de dados
  const [confirmateDelete, setConfirmateDelete] = useState("");
  const [people, setPeople] = useState([]);
  const [usuario, setUsuario] = useState([]);
  const [bodyResquest, setBodyRequest] = useState({});

  // constante de manipulação de elementos
  const [loading, setLoading] = useState(true);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [errorBodyRequest, setErrorBodyRequest] = useState(false);
  const [confirmateDeleteOK, setConfirmateDeleteOK] = useState(false);

  // constante de linguagem do datagrid
  const theme = useTheme();

  // constantes de estilização de componente
  const pageSize = 10;
  const deleteIconStyle = { color: "red" };
  const editIconStyle = { color: "#510EA5" };

  // Função abrir modal de deletar usuario
  const handleOpenModalDelete = (user) => {
    setOpenModalDelete(true);
    setUsuario(user);
  };

  // FUnção fecha modal de deletar usuário
  const handleClose = () => {
    setOpenModalDelete(false);
    setUsuario([]);
  };

  // Função abrir modal de editar usuario
  const handleOpenModalEdit = (user) => {
    setOpenModalEdit(true);
    setUsuario(user);
  };

  // Função de fechar modal de editar usuario
  const handleCloseEdit = () => {
    setOpenModalEdit(false);
    setUsuario([]);
  };

  // Função abrir modal de adicionar usuario
  const handleOpenModalAdd = () => {
    setOpenModalAdd(true);
  };

  // Função de fechar modal de adicionar usuario
  const handleCloseAdd = () => {
    setOpenModalAdd(false);
  };

  // Confirmação de texto "Confirmar"
  const handleConfirmText = (value) => {
    setConfirmateDeleteOK(false);
    setConfirmateDelete(value.target.value);
  };

  // notificação de erro
  const notifyError = (text) => toast.error(text);

  // notificação de sucesso
  const notifySucess = (text) => toast.success(text);

  // Validador de objeto vazio
  function isEmpty(obj) {
    return Object.values(obj).length === 0;
  }

  const exportToCSV = (data, columns) => {
    // Filtrar colunas relevantes para exportação
    const exportableColumns = columns.filter((col) => !col.renderCell);

    // Mapear cabeçalhos das colunas
    const headers = exportableColumns.map((col) => col.headerName);

    // Mapear dados das linhas
    const rows = data.map((row) =>
      exportableColumns.map((col) => row[col.field])
    );

    // Criar a string CSV
    const csv = Papa.unparse([headers, ...rows]);

    // Criar um elemento de link para baixar o arquivo
    const link = document.createElement("a");
    link.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
    link.target = "_blank";
    link.download = "data.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportToXLSX = (data, columns) => {
    // Filtrar colunas relevantes para exportação
    const exportableColumns = columns.filter((col) => !col.renderCell);

    // Mapeamento dos dados para o formato necessário
    const exportData = data.map((row) => {
      let rowData = {};
      exportableColumns.forEach((col) => {
        rowData[col.headerName] = row[col.field];
      });
      return rowData;
    });

    // Criar uma worksheet a partir dos dados
    const worksheet = XLSX.utils.json_to_sheet(exportData);

    // Criar uma workbook e adicionar a worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Gerar e salvar o arquivo Excel
    XLSX.writeFile(workbook, "data.xlsx");
  };

  // Confirmação de Criação de Usuario
  const handleCreateUser = async () => {
    if (!isEmpty(bodyResquest)) {
      try {
        await axios
          .post(API_ROUTES.CREATE_PEOPLE_EFNI, bodyResquest)
          .then((res) => {
            setOpenModalAdd(false);
            notifySucess("Usuário Cadastrado com sucesso!");
            setLoading(true);
            setUsuario([]);
            fetchPeople();
            setErrorBodyRequest(false);
          })
          .catch((err) => {});
      } catch (err) {
        setOpenModalEdit(false);
        notifyError("Erro ao alterar usuário, tente novamente mais tarde!");
      }
    } else {
      setErrorBodyRequest(true);
    }
  };

  // Confirmação de alteração de usuario
  const handleAlternateUser = async () => {
    if (!isEmpty(bodyResquest)) {
      try {
        await axios
          .put(API_ROUTES.ALTERNATE_PEOPLE_EFNI(usuario.usuario), bodyResquest)
          .then((res) => {
            setOpenModalEdit(false);
            notifySucess("Usuário Alterado com sucesso!");
            setLoading(true);
            setUsuario([]);
            fetchPeople();
            setErrorBodyRequest(false);
          })
          .catch((err) => {});
      } catch (err) {
        setOpenModalEdit(false);
        notifyError("Erro ao alterar usuário, tente novamente mais tarde!");
      }
    } else {
      setErrorBodyRequest(true);
    }
  };

  // Confirmação de deleção de Usuário
  const handleConfirmDelete = async () => {
    if (confirmateDelete.toLowerCase() === "confirmar") {
      try {
        await axios
          .delete(API_ROUTES.DELETE_PEOPLE_EFNI(usuario.usuario))
          .then((res) => {
            notifySucess("Usuário deletado com sucesso!");
            setOpenModalDelete(false);
            setConfirmateDelete("");
            setLoading(true);
            setUsuario([]);
            fetchPeople();
          })
          .catch((err) => {});
      } catch (err) {
        notifyError("Erro ao excluir usuário, tente novamente mais tarde!");
      }
    } else {
      setConfirmateDeleteOK(true);
    }
  };

  // Função que captura usuarios do banco
  const fetchPeople = async () => {
    try {
      const response = await axios.get(API_ROUTES.PEOPLE_EFNI, {
        params: {
          _page: 1, // Começa da primeira página
          _limit: pageSize,
        },
      });
      const data = response.data;

      const mappedData = data.map((person, index) => ({
        id: index,
        name: person.contact_info.name,
        email: person.contact_info.email,
        telefone: person.contact_info.phone,
        usuario: person.username,
        documento: person.contact_info.document,
        endereco: person.contact_info.address,
        grupo: person.sector.group,
        funcao: person.sector.function,
        typeUser: person.sector.hierarchy,
        typeUserData:
          person.sector.hierarchy == "0"
            ? "Administrador"
            : person.sector.hierarchy == "1"
            ? "Interno"
            : "Externo",
        // Adicionando campo para ícones
        icons: {
          person: <EditOutlinedIcon />,
          delete: <DeleteOutlineOutlinedIcon />,
        },
        password: person.password,
      }));
      setPeople(mappedData);
      setLoading(false); // Marca o carregamento como concluído
    } catch (error) {
      console.error("Erro ao buscar pessoas:", error);
      setLoading(false); // Mesmo em caso de erro, marca o carregamento como concluído
    }
  };

  // Captura usuarios do banco
  useEffect(() => {
    fetchPeople();
  }, []);

  // Colunas do datagrid
  const columns = [
    {
      field: "icons",
      headerName: "",
      width: 1,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <IconButton onClick={() => handleOpenModalEdit(params.row)}>
            <EditOutlinedIcon sx={editIconStyle} />{" "}
            {/* Aplica o estilo de cor azul */}
          </IconButton>
        </div>
      ),
    },
    {
      field: "deleteIcon",
      headerName: "",
      width: 1,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <IconButton onClick={() => handleOpenModalDelete(params.row)}>
            <DeleteOutlineOutlinedIcon sx={deleteIconStyle} />{" "}
            {/* Aplica o estilo de cor vermelha */}
          </IconButton>
        </div>
      ),
    },
    { field: "name", headerName: "Nome", width: 200 },
    { field: "email", headerName: "Email", width: 250 },
    { field: "telefone", headerName: "Telefone", width: 250 },
    { field: "usuario", headerName: "Usuário", width: 250 },
    { field: "typeUserData", headerName: "Tipo Usuário", width: 250 },
    { field: "grupo", headerName: "Grupo", width: 250 },
    { field: "funcao", headerName: "Função", width: 250 },
    { field: "documento", headerName: "Documento", width: 250 },
    { field: "endereco", headerName: "Endereço", width: 250 },
  ];

  // Tradução do datagrid
  const themeWithLocale = React.useMemo(
    () => createTheme(theme, locales["ptBR"]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ["ptBR", theme]
  );

  // Estilização do modal
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    height: confirmateDeleteOK ? 350 : 300,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "8px", // Bordas arredondadas
    textAlign: "center", // Alinhamento do texto
  };

  // Estilizador do modal de edição de usuario / adição de usuario
  const modalStyleAddOrEditUser = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60vw",
    height: "90%",
    bgcolor: "background.paper",
    p: 4,
    borderRadius: "8px", // Bordas arredondadas
    textAlign: "center", // Alinhamento do texto
    overflowY: "scroll",
    scrollbarWidth: "none", // Oculta a barra de rolagem no Firefox
    "&::-webkit-scrollbar": {
      display: "none", // Oculta a barra de rolagem no Chrome, Safari e Edge
    },
  };

  useEffect(() => {
    setErrorBodyRequest(false);
  }, [bodyResquest]);

  return (
    <div className={style["App"]}>
      {/* Modal de Excluir Usuario */}
      <Modal
        open={openModalDelete}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Deseja realmente <strong>remover</strong> o usuário {usuario.name}?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Caso sim, digite <a>CONFIRMAR</a>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            para realizar o processo.
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 4 }}>
            <Input
              className={style["inputConfirmation"]}
              onChange={(value) => handleConfirmText(value)}
            ></Input>
          </Typography>

          {confirmateDeleteOK ? (
            <Typography id="modal-modal-description" sx={{ mt: 7 }}>
              <p style={{ color: "red" }}>
                Para realizar o processo digite 'Confirmar' corretamente.
              </p>
            </Typography>
          ) : null}

          <Typography id="modal-modal-description" sx={{ mt: 7.5 }}>
            <div className={style["buttons"]}>
              <Button
                variant="contained"
                style={{ marginRight: 15 }}
                onClick={() => handleConfirmDelete()}
              >
                CONFIRMAR
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => handleClose()}
              >
                CANCELAR
              </Button>
            </div>
          </Typography>
        </Box>
      </Modal>

      {/* Modal de Editar Usuario */}
      <Modal
        open={openModalEdit}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyleAddOrEditUser}>
          <div>
            <BodyModal
              usuario={usuario ? usuario : "nada"}
              bodyRequest={(value) => setBodyRequest(value)}
            />

            <footer>
              <div className={style["buttons"]}>
                {errorBodyRequest ? (
                  <div>
                    <p>Verifique os campos obrigatórios e de confirmação</p>
                    <p>para realizar o processo de alteração!</p>
                  </div>
                ) : null}
                <Button
                  variant="contained"
                  style={{ marginRight: 15, marginLeft: 15 }}
                  onClick={() => handleAlternateUser()}
                >
                  Alterar
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleCloseEdit()}
                >
                  CANCELAR
                </Button>
              </div>
            </footer>
          </div>
        </Box>
      </Modal>

      {/* Modal de Adicionar Usuario */}
      <Modal
        open={openModalAdd}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyleAddOrEditUser}>
          <div>
            <BodyModal
              usuario={""}
              bodyRequest={(value) => setBodyRequest(value)}
            />

            <footer>
              <div className={style["buttons"]}>
                {errorBodyRequest ? (
                  <div>
                    <p>Verifique os campos obrigatórios e de confirmação</p>
                    <p>para realizar o processo de alteração!</p>
                  </div>
                ) : null}
                <Button
                  variant="contained"
                  style={{ marginRight: 15, marginLeft: 15 }}
                  onClick={() => handleCreateUser()}
                >
                  Cadastrar
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleCloseAdd()}
                >
                  CANCELAR
                </Button>
              </div>
            </footer>
          </div>
        </Box>
      </Modal>

      {/* Renderização de Datagrid */}
      {!loading && (
        <Box sx={{ width: "100%", height: "calc(100% - 100px)" }}>
          <div style={{ width: "100%", display: "flex" }}>
            <Button
              variant="contained"
              style={{
                margin: 15,
                backgroundColor: "#8947f4",
                borderRadius: "9px",
                width: "210px",
              }}
              onClick={() => handleOpenModalAdd()}
            >
              <AddCircleOutlineIcon style={{ marginRight: "2%" }} /> Adicionar
              Usuário
            </Button>
            <Button
              variant="contained"
              style={{
                margin: 15,
                backgroundColor: "#228B22",
                borderRadius: "9px",
                width: "210px",
              }}
              onClick={() => exportToCSV(people, columns)}
            >
              <FileDownloadOutlinedIcon style={{ marginRight: "15%" }} /> Exportar
              CSV
            </Button>
            <Button
              variant="contained"
              style={{
                margin: 15,
                backgroundColor: "#228B22",
                borderRadius: "9px",
                width: "210px",
              }}
              onClick={() => exportToXLSX(people, columns)}
            >
              <FileDownloadOutlinedIcon style={{ marginRight: "15%" }} /> Exportar
              XLSX
            </Button>
          </div>
          <ThemeProvider theme={themeWithLocale}>
            <DataGrid
              rows={people}
              columns={columns}
              localeText={localeText}
              pageSize={pageSize}
              paginationMode="client"
              pagination={true}
              location={"ptBR"}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 15,
                  },
                },
              }}
            />
          </ThemeProvider>
        </Box>
      )}

      {/* Renderização de Carregamento de pagina */}
      {loading && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <ToastContainer />
    </div>
  );
}

export default BodyPeopleBodyEFNI;
