// Importação de componentes e do React
import React, { useEffect, useState } from "react";

// Importação de estilização
import style from "./bodyModal.module.css";

// Importação de bibliotecas terceiras
import Box from "@mui/material/Box";
import KeyIcon from "@mui/icons-material/Key";
import RoomIcon from "@mui/icons-material/Room";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import ArticleIcon from "@mui/icons-material/Article";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";

function BodyModal({ usuario, bodyRequest }) {
  // state de manipulação de dados
  const [confirmEmail, setConfirmEmail] = useState("");
  const [confirmPhone, setConfirmPhone] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [name, setName] = useState(usuario ? usuario.name : "");
  const [email, setEmail] = useState(usuario ? usuario.email : "");
  const [group, setGroup] = useState(usuario ? usuario.grupo : "");
  const [phone, setPhone] = useState(usuario ? usuario.telefone : "");
  const [address, setAddress] = useState(usuario ? usuario.endereco : "");
  const [username, setUsername] = useState(usuario ? usuario.usuario : "");
  const [password, setPassword] = useState(usuario ? usuario.password : "");
  const [typeUser, setTypeUser] = useState(usuario ? usuario.typeUser : "");
  const [document, setDocument] = useState(usuario ? usuario.documento : "");
  const [functionUser, setFunction] = useState(usuario ? usuario.funcao: "");


  // State de manipulação de elemento
  const [errorName, setErrorName] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorTypeUser, setErrorTypeUser] = useState(false);
  const [errorTypeUsername, setErrorUserName] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);

  // Modifica a visualização da senha
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  // Modifica a visualização da senha
  const handleClickConfirmShowPassword = () =>
    setConfirmShowPassword((show) => !show);

  // Validador de formatação de número de telefone
  function isValidPhoneNumber(phone) {
    // Expressão regular para validar o formato +5511987654321
    const regex = /^\+55\d{11}$/;

    // Testa o número com a expressão regular
    return regex.test(phone);
  }

  // Modifica nome
  const handleChangeName = (value) => {
    setName(value.target.value);
  };

  // Modifica username
  const handleChangeUsername = (value) => {
    setUsername(value.target.value);
  };

  // Modifica email
  const handleChangeEmail = (value) => {
    setEmail(value.target.value);
  };

  // Modifica confirm email
  const handleChangeConfirmEmail = (value) => {
    setConfirmEmail(value.target.value);
  };

  // Modifica telefone
  const handleChangePhone = (value) => {
    setPhone(value.target.value);
  };

  // Modifica confirm telefone
  const handleChangeConfirmPhone = (value) => {
    setConfirmPhone(value.target.value);
  };

  // Modifica senha
  const handleChangePassword = (value) => {
    setPassword(value.target.value);
  };

  // Modifica confirm senha
  const handleChangeConfirmPassword = (value) => {
    setConfirmPassword(value.target.value);
  };


  // Modifica endereço
  const handleChangeAddress = (value) => {
    setAddress(value.target.value);
  };

  // Modifica tipo usuario
  const handleChangeTypeUser = (value) => {
    setTypeUser(value.target.value.toString());
  };

  // Modifica grupo
  const handleChangeGroup = (value) => {
    setGroup(value.target.value);
  };

  // Modifica Função
  const handleChangeFunctionUser = (value) => {
    setFunction(value.target.value);
  };


  // Validador/indexador/modificador de component pai
  const handleChangeUser = () => {
    let verifyPass = password === confirmPassword;
    let verifyEmail = email === confirmEmail;
    let verifyPhone = phone === confirmPhone;
    let verifyTypeUser = typeUser !== "";
    let verifyName = name !== "";
    let verifyUsername = username !== "";

    if (verifyPass) {
      setErrorPassword(false);
    } else {
      setErrorPassword(true);
    }

    if (verifyEmail) {
      setErrorEmail(false);
    } else {
      setErrorEmail(true);
    }

    if (verifyPhone && isValidPhoneNumber(phone)) {
      setErrorPhone(false);
    } else {
      setErrorPhone(true);
    }

    if (verifyTypeUser) {
      setErrorTypeUser(false);
    } else {
      setErrorTypeUser(true);
    }

    if (verifyName) {
      setErrorName(false);
    } else {
      setErrorName(true);
    }

    // if (verifyUsername) {
    //   setErrorUserName(false);
    // } else {
    //   setErrorUserName(true);
    // }

    if (
      verifyEmail &&
      verifyPass &&
      verifyPhone &&
      isValidPhoneNumber(phone) &&
      verifyTypeUser &&
      verifyName 
      // verifyUsername
    ) {
      bodyRequest({
        username: username,
        password: password,
        contact_info: {
          name: name,
          email: email,
          phone: phone,
          document: document,
          address: address,
        },
        sector: {
          hierarchy: typeUser,
          group: group,
          function: functionUser
        },
      });
    } else {
      bodyRequest({});
    }
  };

  // Modificador de state do component pai
  useEffect(() => {
    handleChangeUser();
  }, [
    username,
    password,
    confirmPassword,
    name,
    email,
    confirmEmail,
    phone,
    confirmPhone,
    address,
    typeUser,
    group,
    functionUser
  ]);

  // Estilo de input
  const styleInput = {
    width: "25ch",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#28177550", // Cor da borda padrão
      },
      "&:hover fieldset": {
        borderColor: "#28177599", // Cor da borda ao passar o mouse
      },
      "&.Mui-focused fieldset": {
        borderColor: "#281775ff", // Cor da borda ao focar
      },
      "&.Mui-selected fieldset": {
        borderColor: "#281775ff",
      },
      "& .MuiInputLabel-root": {
        color: "red", // Cor do texto do rótulo
      },
    },
    marginTop: "1.5rem",
  };

  // Estilo do label do input
  const styleColorLabelInput = {
    color: "#00070",
    "&.Mui-focused": {
      color: "#281775ff", // Cor quando o rótulo está focado
    },
  };
  return (
    <div className={style["App"]}>
      <div className={style["container-personal-data"]}>
        <div className={style["title-section"]}>
          <p>Dados Pessoais</p>
        </div>
        {/* Dados Pessoais */}
        <div className={style["body-section"]}>
          <div className={style["column-inputs"]}>
            {/* Nome */}
            <Box
              component="form"
              sx={{
                "& > :not(style)": { width: "95%", marginTop: "2.5%" },
              }}
              noValidate
              autoComplete="off"
            >
              <FormControl sx={styleInput} variant="outlined" error={errorName}>
                <InputLabel
                  htmlFor="outlined-adornment-password"
                  sx={styleColorLabelInput}
                >
                  Nome<strong>*</strong>
                </InputLabel>
                <OutlinedInput
                  required={true}
                  type={"text"}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="start"
                      >
                        <PersonIcon style={{ color: "#4B4B4B" }} />
                      </IconButton>
                    </InputAdornment>
                  }
                  value={name}
                  onChange={(value) => {
                    handleChangeName(value);
                  }}
                  label="Nome*"
                />
              </FormControl>
            </Box>

            {/* Endereço */}
            <Box
              component="form"
              sx={{
                "& > :not(style)": { width: "95%", marginTop: "2.5%" },
              }}
              noValidate
              autoComplete="off"
            >
              <FormControl sx={styleInput} variant="outlined">
                <InputLabel
                  htmlFor="outlined-adornment-password"
                  sx={styleColorLabelInput}
                >
                  Endereço
                </InputLabel>
                <OutlinedInput
                  type={"text"}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        //   onClick={handleClickShowPassword}
                        //   onMouseDown={handleMouseDownPassword}
                        edge="start"
                      >
                        <RoomIcon style={{ color: "#4B4B4B" }} />
                      </IconButton>
                    </InputAdornment>
                  }
                  value={address}
                  onChange={(value) => {
                    handleChangeAddress(value);
                  }}
                  label="Endereço"
                />
              </FormControl>
            </Box>
          </div>
          <div className={style["column-inputs"]}>
            {/* Email */}
            <Box
              component="form"
              sx={{
                "& > :not(style)": { width: "95%", marginTop: "2.5%" },
              }}
              noValidate
              autoComplete="off"
            >
              <FormControl
                sx={styleInput}
                variant="outlined"
                error={errorEmail}
              >
                <InputLabel
                  htmlFor="outlined-adornment-password"
                  sx={styleColorLabelInput}
                >
                  Email<strong>*</strong>
                </InputLabel>
                <OutlinedInput
                  type={"text"}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        //   onClick={handleClickShowPassword}
                        //   onMouseDown={handleMouseDownPassword}
                        edge="start"
                      >
                        <AlternateEmailIcon style={{ color: "#4B4B4B" }} />
                      </IconButton>
                    </InputAdornment>
                  }
                  value={email}
                  onChange={(value) => {
                    handleChangeEmail(value);
                  }}
                  label="Email*"
                />
              </FormControl>
            </Box>

            {/* Confirmar Email */}
            <Box
              component="form"
              sx={{
                "& > :not(style)": { width: "95%", marginTop: "2.5%" },
              }}
              noValidate
              autoComplete="off"
            >
              <FormControl
                sx={styleInput}
                variant="outlined"
                error={errorEmail}
              >
                <InputLabel
                  htmlFor="outlined-adornment-password"
                  sx={styleColorLabelInput}
                >
                  Confirmar Email<strong>*</strong>
                </InputLabel>
                <OutlinedInput
                  type={"text"}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="start"
                      >
                        <AlternateEmailIcon style={{ color: "#4B4B4B" }} />
                      </IconButton>
                    </InputAdornment>
                  }
                  value={confirmEmail}
                  onChange={(value) => {
                    handleChangeConfirmEmail(value);
                  }}
                  label="Confirmar Email*"
                />
              </FormControl>
            </Box>
          </div>
          <div className={style["column-inputs"]}>
            {/* Telefone */}
            <Box
              component="form"
              sx={{
                "& > :not(style)": { width: "95%", marginTop: "2.5%" },
              }}
              noValidate
              autoComplete="off"
            >
              <FormControl
                sx={styleInput}
                variant="outlined"
                error={errorPhone}
              >
                <InputLabel
                  htmlFor="outlined-adornment-password"
                  sx={styleColorLabelInput}
                >
                  Telefone<strong>*</strong>
                </InputLabel>
                <OutlinedInput
                  type={"text"}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        //   onClick={handleClickShowPassword}
                        //   onMouseDown={handleMouseDownPassword}
                        edge="start"
                      >
                        <PhoneEnabledIcon style={{ color: "#4B4B4B" }} />
                      </IconButton>
                    </InputAdornment>
                  }
                  value={phone}
                  onChange={(value) => {
                    handleChangePhone(value);
                  }}
                  label="Telefone*"
                />
              </FormControl>
              <InputLabel
                sx={{
                  fontSize: "8pt",
                  width: "100%",
                  textAlign: "start",
                  marginLeft: "5%",
                }}
              >
                Ex: +5511987654321
              </InputLabel>
            </Box>

            {/* Confirmar Telefon */}
            <Box
              component="form"
              sx={{
                "& > :not(style)": { width: "95%", marginTop: "2.5%" },
              }}
              noValidate
              autoComplete="off"
            >
              <FormControl
                sx={styleInput}
                variant="outlined"
                error={errorPhone}
              >
                <InputLabel
                  htmlFor="outlined-adornment-password"
                  sx={styleColorLabelInput}
                >
                  Confirmar Telefone<strong>*</strong>
                </InputLabel>
                <OutlinedInput
                  type={"text"}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="start"
                      >
                        <PhoneEnabledIcon style={{ color: "#4B4B4B" }} />
                      </IconButton>
                    </InputAdornment>
                  }
                  value={confirmPhone}
                  onChange={(value) => {
                    handleChangeConfirmPhone(value);
                  }}
                  label="Confirmar Telefone*"
                />
              </FormControl>
              <InputLabel
                sx={{
                  fontSize: "8pt",
                  width: "100%",
                  textAlign: "start",
                  marginLeft: "5%",
                }}
              >
                Ex: +5511987654321
              </InputLabel>
            </Box>
          </div>
        </div>
      </div>
      <div className={style["container-access-data"]}>
        <div className={style["title-section"]}>
          <p>Dados de Acesso</p>
        </div>
        {/* Dados de Acesso */}
        <div className={style["body-section"]}>
          <div className={style["column-inputs"]}>
            {/* Usuario */}
            <Box
              component="form"
              sx={{
                "& > :not(style)": { width: "95%", marginTop: "2.5%" },
              }}
              noValidate
              autoComplete="off"
            >
              <FormControl
                sx={styleInput}
                variant="outlined"
                error={errorTypeUsername}
              >
                <InputLabel
                  htmlFor="outlined-adornment-password"
                  sx={styleColorLabelInput}
                >
                  Usuário
                </InputLabel>
                <OutlinedInput
                  type={"text"}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="start"
                      >
                        <PersonIcon style={{ color: "#4B4B4B" }} />
                      </IconButton>
                    </InputAdornment>
                  }
                  value={username}
                  onChange={(value) => {
                    handleChangeUsername(value);
                  }}
                  label="Usuário*"
                />
              </FormControl>
            </Box>

            {/* Tipo Usuario */}
            <Box
              component="form"
              sx={{
                "& > :not(style)": { width: "95%", marginTop: "2.5%" },
              }}
              noValidate
              autoComplete="off"
            >
              <FormControl
                sx={styleInput}
                variant="outlined"
                error={errorTypeUser}
              >
                <InputLabel
                  htmlFor="outlined-adornment-select"
                  sx={styleColorLabelInput}
                >
                  Tipo Usuário<strong>*</strong>
                </InputLabel>
                <Select
                  labelId="outlined-adornment-select"
                  id="outlined-adornment-select"
                  value={typeUser}
                  onChange={handleChangeTypeUser}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton aria-label="user type icon" edge="start">
                        <ManageAccountsIcon style={{ color: "#4B4B4B" }} />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Tipo Usuário*"
                  style={{ textAlign: "start" }}
                >
                  <MenuItem value="">
                    <em></em>
                  </MenuItem>
                  <MenuItem value="0">Administrador</MenuItem>
                  <MenuItem value="1">Interno</MenuItem>
                  <MenuItem value="2">Externo</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
          <div className={style["column-inputs"]}>
            {/* Senha */}
            <Box
              component="form"
              sx={{
                "& > :not(style)": { width: "95%", marginTop: "2.5%" },
              }}
              noValidate
              autoComplete="off"
            >
              <FormControl
                sx={styleInput}
                variant="outlined"
                error={errorPassword}
              >
                <InputLabel
                  htmlFor="outlined-adornment-password"
                  sx={styleColorLabelInput}
                >
                  Senha<strong>*</strong>
                </InputLabel>
                <OutlinedInput
                  type={showPassword ? "text" : "password"}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        //   onClick={handleClickShowPassword}
                        //   onMouseDown={handleMouseDownPassword}
                        edge="start"
                      >
                        <KeyIcon style={{ color: "#4B4B4B" }} />
                      </IconButton>
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  value={password}
                  onChange={(value) => {
                    handleChangePassword(value);
                  }}
                  label="Senha*"
                />
              </FormControl>
            </Box>

            {/* Confirmar Senha */}
            <Box
              component="form"
              sx={{
                "& > :not(style)": { width: "95%", marginTop: "2.5%" },
              }}
              noValidate
              autoComplete="off"
            >
              <FormControl
                sx={styleInput}
                variant="outlined"
                error={errorPassword}
              >
                <InputLabel
                  htmlFor="outlined-adornment-password"
                  sx={styleColorLabelInput}
                >
                  Confirmar Senha<strong>*</strong>
                </InputLabel>
                <OutlinedInput
                  type={showConfirmPassword ? "text" : "password"}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="start"
                      >
                        <KeyIcon style={{ color: "#4B4B4B" }} />
                      </IconButton>
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickConfirmShowPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  value={confirmPassword}
                  onChange={(value) => handleChangeConfirmPassword(value)}
                  label="Confirmar Senha*"
                />
              </FormControl>
            </Box>
          </div>
          <div className={style["column-inputs"]}>

            {/* Grupo */}
            <Box
              component="form"
              sx={{
                "& > :not(style)": { width: "95%", marginTop: "2.5%" },
              }}
              noValidate
              autoComplete="off"
            >
              <FormControl
                sx={styleInput}
                variant="outlined"
              >
                <InputLabel
                  htmlFor="outlined-adornment-select"
                  sx={styleColorLabelInput}
                >
                  Grupo
                </InputLabel>
                <Select
                  labelId="outlined-adornment-select"
                  id="outlined-adornment-select"
                  value={group}
                  onChange={handleChangeGroup}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton aria-label="user type icon" edge="start">
                        <ManageAccountsIcon style={{ color: "#4B4B4B" }} />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Grupo"
                  style={{ textAlign: "start" }}
                >
                  <MenuItem value="">
                    <em></em>
                  </MenuItem>
                  <MenuItem value="Nível 1">Nível 1</MenuItem>
                  <MenuItem value="Nível 2">Nível 2</MenuItem>
                  <MenuItem value="Nível 3">Nivel 3</MenuItem>
                  <MenuItem value="Simulado">Simulado</MenuItem>
                  <MenuItem value="efni">EFNI</MenuItem>
                </Select>
              </FormControl>
            </Box>
            
            {/* Função */}
            <Box
              component="form"
              sx={{
                "& > :not(style)": { width: "95%", marginTop: "2.5%" },
              }}
              noValidate
              autoComplete="off"
            >
              <FormControl
                sx={styleInput}
                variant="outlined"
              >
                <InputLabel
                  htmlFor="outlined-adornment-select"
                  sx={styleColorLabelInput}
                >
                  Função
                </InputLabel>
                <Select
                  labelId="outlined-adornment-select"
                  id="outlined-adornment-select"
                  value={functionUser}
                  onChange={handleChangeFunctionUser}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton aria-label="user type icon" edge="start">
                        <ManageAccountsIcon style={{ color: "#4B4B4B" }} />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Grupo"
                  style={{ textAlign: "start" }}
                >
                  <MenuItem value="">
                    <em></em>
                  </MenuItem>
                  
                  <MenuItem value="Agente ACO/DCO">Agente ACO/DCO</MenuItem>
                  <MenuItem value="Responsabilidade Social">Responsabilidade Social</MenuItem>
                  <MenuItem value="Medicina do Trabalho">Medicina do Trabalho</MenuItem>
                  <MenuItem value="Regulatório">Regulatório</MenuItem>
                  <MenuItem value="Relações Institucionais">Relações Institucionais</MenuItem>
                  <MenuItem value="Planejamento de Lavra">Planejamento de Lavra</MenuItem>
                  <MenuItem value="Jurídico">Jurídico</MenuItem>
                  <MenuItem value="Suprimentos">Suprimentos</MenuItem>
                  <MenuItem value="Segurança Patrimonial">Segurança Patrimonial</MenuItem>
                  <MenuItem value="Comunicação Interna">Comunicação Interna</MenuItem>
                  <MenuItem value="Meio Ambiente">Meio Ambiente</MenuItem>
                  <MenuItem value="Operação de Mina">Operação de Mina</MenuItem>
                  <MenuItem value="Financeiro">Financeiro</MenuItem>
                  <MenuItem value="Gestão de Riscos e Emergências - PAEBM">Gestão de Riscos e Emergências - PAEBM</MenuItem>
                  <MenuItem value="Segurança do Trabalho">Segurança do Trabalho</MenuItem>
                  <MenuItem value="Recursos Humanos">Recursos Humanos</MenuItem>
                  <MenuItem value="Geotecnia e Centro de Monitoramento Geotécnico (CMG)">Geotecnia e Centro de Monitoramento Geotécnico (CMG)</MenuItem>
               
                </Select>
              </FormControl>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BodyModal;
