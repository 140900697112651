// Importação de Style
import style from "./header.module.css";


function Header() {
  let user = JSON.parse(localStorage.getItem("user"));

  return (
    <div className={style["App"]}>
      <div className={style["saudacao"]}>Olá, {user.contact_info.name}</div>
    </div>
  );
}

export default Header;